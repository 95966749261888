import { gql } from '@apollo/client';
import { FetchError, FetchFunction } from '@spaceship-fspl/fetch';
import { MfaVerificationType } from '@spaceship-fspl/graphql';
import {
  MfaVerificationAnswer,
  Scope,
} from '@spaceship-fspl/graphql/src/__generated__/globalTypes';
import {
  Login,
  Login_login_mfaChallenge,
  LoginVariables,
} from '@spaceship-fspl/graphql/src/__generated__/Login';
import {
  RefreshToken,
  RefreshTokenVariables,
} from '@spaceship-fspl/graphql/src/__generated__/RefreshToken';
import { IIntercomHashes } from '@spaceship-fspl/types/externalapi';
import { print } from 'graphql';
import { decode as decodeBase64 } from 'js-base64';
import { v4 as uuidV4 } from 'uuid';

export { Scope } from '@spaceship-fspl/graphql/src/__generated__/globalTypes';

const millisecondsBeforeAccessTokenExpiresToRefresh = 5 * 1000;

export class AuthError extends Error {
  public errors: Error[];
  public isSpaceshipUnauthorized: boolean;
  public isContactSupport: boolean;
  public isMfaRateLimited: boolean;
  public requestId: string;

  constructor(errors: Error[], requestId: string) {
    const message = errors.map((error) => error.message).join(', ');
    super(message);

    this.isSpaceshipUnauthorized = errors.some((error) =>
      error.message?.match(/credential mismatch/i),
    );
    this.isMfaRateLimited = errors.some((error) =>
      error.message?.match(/too many verification attempts/i),
    );
    this.isContactSupport = errors.some((error) =>
      error.message?.match(/contact support/i),
    );
    this.errors = errors;
    this.requestId = requestId;
  }
}

export const decodeJWT = (jwt: string): { [name: string]: unknown } => {
  const base64Payload = jwt.split('.')[1];
  if (!base64Payload) {
    throw new Error('JWT format is invalid.');
  }

  const payload = decodeBase64(base64Payload);
  return JSON.parse(payload.toString());
};

export function getMillisecondsUntilIsExpired({
  expiresAt,
  jwtToken,
}: {
  expiresAt?: string | null;
  jwtToken?: string | null;
}): number {
  if (expiresAt) {
    const date = new Date(expiresAt);
    if (isNaN(date.getTime())) {
      throw new Error('Refresh Expiry is invalid.');
    }

    return Math.max(0, date.getTime() - Date.now());
  } else if (jwtToken) {
    const jwt = decodeJWT(jwtToken);
    if (typeof jwt.exp === 'number') {
      return Math.max(0, jwt.exp * 1000 - Date.now());
    }
  }

  return Infinity;
}

function transformLegacyScopes(scopes: Array<string>): Array<Scope> {
  return scopes.map((scope) => {
    switch (scope) {
      case 'basic:read': {
        return Scope.BASIC_READ;
      }
      case 'account:owner:read': {
        return Scope.ACCOUNT_READ;
      }
      case 'account:owner:write': {
        return Scope.ACCOUNT_WRITE;
      }
      case 'account:owner:verified': {
        return Scope.ACCOUNT_VERIFIED;
      }
      case 'saver:owner:read': {
        return Scope.SAVER_READ;
      }
      case 'saver:owner:write': {
        return Scope.SAVER_WRITE;
      }
      case 'super:owner:read': {
        return Scope.SUPER_READ;
      }
      case 'super:owner:verified': {
        return Scope.SUPER_VERIFIED;
      }
      case 'https://api.impactcrm.com.au/member': {
        return Scope.SARGON_READ_WRITE;
      }
      default:
        return scope;
    }
  }) as Array<Scope>;
}

interface ClientOtherStuffState {
  intercom_hashes?: IIntercomHashes | null;
  iterableAuthToken?: string | null;
}

interface ClientAuthInfoState {
  expiresAt?: string | null;
  refreshExpiresAt?: string | null;
}

export interface ClientState {
  status: Status | undefined;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  scopes: Array<Scope> | undefined;
  id: string | undefined;
  otherStuff: ClientOtherStuffState | undefined;
  authInfo: ClientAuthInfoState | undefined;
}

export enum Status {
  UNAUTHENTICATED = 'unauthenticated',
  AUTHENTICATED = 'authenticated',
}

export interface ClientStorage {
  read: () => Promise<ClientState | undefined>;
  write: (state: ClientState) => Promise<void>;
}

export interface ClientListener {
  (): void;
}

export interface ClientOptions {
  fetch: FetchFunction;
  ddRum?: (message: string, error?: Error) => Promise<void>;
  storage?: ClientStorage;
  useCookies?: boolean;
}

type GraphQLResponse<T> = {
  data: T;
  errors?: Array<Error>;
};

export class Client {
  private state: ClientState = {
    id: undefined,
    status: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    scopes: undefined,
    otherStuff: undefined,
    authInfo: undefined,
  };

  private fetch: FetchFunction;
  private ddRum: ClientOptions['ddRum'];
  private storage: ClientStorage | undefined;
  private useCookies: boolean;
  private listeners: ClientListener[] = [];
  private refreshing: Promise<void> | undefined = undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private refreshTokenExpiryTimeout: any | undefined = undefined;

  public constructor({ fetch, ddRum, storage, useCookies }: ClientOptions) {
    this.fetch = fetch;
    this.ddRum = ddRum;
    this.storage = storage;
    this.useCookies = useCookies || false;

    this.storage?.read().then((state) => {
      // Check for status specifically to force-clear state
      // for apps with stale persisted data.
      if (state?.status) {
        const { scopes, ...rest } = state;
        this.updateState({
          ...rest,
          scopes: scopes
            ? transformLegacyScopes(scopes as Array<string>)
            : scopes,
        });
      } else {
        this.updateState({
          id: undefined,
          status: Status.UNAUTHENTICATED,
          accessToken: undefined,
          refreshToken: undefined,
          scopes: undefined,
          otherStuff: undefined,
          authInfo: undefined,
        });
      }
    });
  }

  public get id(): string | undefined {
    return this.state.id;
  }

  public get status(): Status | undefined {
    return this.state.status;
  }

  public get accessToken(): string | undefined {
    return this.state.accessToken;
  }

  public get refreshToken(): string | undefined {
    return this.state.refreshToken;
  }

  public get scopes(): Array<Scope> | undefined {
    return this.state.scopes;
  }

  public get otherStuff(): ClientOtherStuffState | undefined {
    return this.state.otherStuff;
  }

  public get authInfo(): ClientAuthInfoState | undefined {
    return this.state.authInfo;
  }

  private async updateState(state: ClientState): Promise<void> {
    this.state = state;

    // write to storage
    await this.storage?.write(this.state);

    // notify listeners
    this.listeners.forEach((listener) => listener());
    clearTimeout(this.refreshTokenExpiryTimeout);
    if (this.status === Status.AUTHENTICATED) {
      const millisecondsUntilRefreshTokenIsExpired =
        getMillisecondsUntilIsExpired({
          jwtToken: this.refreshToken,
          expiresAt: this.authInfo?.refreshExpiresAt,
        });
      // logout now if the token has already expired
      if (millisecondsUntilRefreshTokenIsExpired === 0) {
        this.ddRum?.('Logout: Refresh token has reached 0');
        this.logout();
        // logout when the token expires in the future
      } else if (
        isFinite(millisecondsUntilRefreshTokenIsExpired) &&
        millisecondsUntilRefreshTokenIsExpired > 0
      ) {
        this.refreshTokenExpiryTimeout = setTimeout(() => {
          this.ddRum?.('Logout: Refresh token has expired');
          this.logout();
        }, millisecondsUntilRefreshTokenIsExpired);
      }
    }
  }

  public on(listener: ClientListener): void {
    if (!this.listeners.includes(listener)) {
      this.listeners.push(listener);
    }
  }

  public off(listener: ClientListener): void {
    const index = this.listeners.indexOf(listener);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  }

  public async login(
    email: string,
    password: string,
    requestId: string = uuidV4(),
    mfaAnswer: MfaVerificationAnswer | undefined = undefined,
    mfaVerificationType: MfaVerificationType | undefined = undefined,
  ): Promise<Login_login_mfaChallenge | void> {
    try {
      const variables: LoginVariables = {
        input: {
          email,
          password,
          mfaAnswer,
          mfaVerificationType,
        },
        useCookies: this.useCookies,
      };

      const response = (await this.fetch({
        method: 'POST',
        url: '/query',
        headers: {
          'x-request-id': requestId,
        },
        body: {
          operationName: 'Login',
          query: print(gql`
            mutation Login($input: LoginInput!, $useCookies: Boolean!) {
              login(input: $input) {
                auth {
                  id
                  scopes
                  ... on Auth @skip(if: $useCookies) {
                    authToken
                    refreshToken
                  }
                }
                intercomHashes {
                  android
                  id
                  ios
                  web
                }
                iterableAuthToken
                mfaChallenge {
                  id
                  type
                }
                authInfo {
                  expiresAt
                  refreshExpiresAt
                }
              }
            }
          `),
          variables,
        },
      })) as GraphQLResponse<Login>;

      if (response.errors) {
        throw new AuthError(response.errors, requestId);
      }

      const login = response.data?.login;
      const auth = login?.auth;
      const mfaChallenge = login?.mfaChallenge;
      const authInfo = login?.authInfo;

      if (auth) {
        await this.updateState({
          id: auth?.id,
          status: Status.AUTHENTICATED,
          accessToken: auth?.authToken,
          refreshToken: auth?.refreshToken,
          scopes: auth?.scopes,
          otherStuff: {
            intercom_hashes: login?.intercomHashes,
            iterableAuthToken: login?.iterableAuthToken,
          },
          authInfo: authInfo
            ? {
                expiresAt: authInfo?.expiresAt,
                refreshExpiresAt: authInfo?.refreshExpiresAt,
              }
            : undefined,
        });
      } else if (mfaChallenge) {
        return mfaChallenge;
      } else {
        throw new Error("No 'auth' or 'mfaChallenge' in response.");
      }
    } catch (error) {
      await this.logout();
      throw error;
    }
  }

  public async logout(): Promise<void> {
    try {
      const id = this.state.id;
      const accessToken = this.accessToken;
      await this.updateState({
        id: undefined,
        status: Status.UNAUTHENTICATED,
        accessToken: undefined,
        refreshToken: undefined,
        scopes: undefined,
        otherStuff: undefined,
        authInfo: undefined,
      });
      if (id) {
        await this.fetch({
          method: 'POST',
          url: '/query',
          headers: {
            ...(!this.useCookies && accessToken
              ? { authorization: `Bearer ${accessToken}` }
              : {}),
          },
          body: {
            operationName: 'RevokeSession',
            query: print(gql`
              mutation RevokeSession($input: RevokeSessionInput!) {
                revokeSession(input: $input) {
                  id
                }
              }
            `),
            variables: {
              input: { id },
            },
          },
        });
      }
    } catch {
      // do nothing
    }
  }

  private forceRefreshing: Promise<void> | undefined = undefined;
  public async forceRefresh(): Promise<void> {
    if (this.forceRefreshing) {
      // wait for the already in-progress refresh to finish refreshing
      try {
        await this.forceRefreshing;
      } catch {
        // we'll rely on refreshToken to log out
      }
    } else {
      // refreshing
      this.forceRefreshing = this.refresh();
      try {
        await this.forceRefreshing;
      } catch {
        // we'll rely on refreshToken to log out
      }

      this.forceRefreshing = undefined;
    }
  }

  public async refresh(): Promise<void> {
    if (!this.refreshToken && !this.authInfo?.refreshExpiresAt) {
      throw new Error('No refreshToken or authInfo refreshExpiresAt.');
    }

    const requestId = uuidV4();
    try {
      const variables: RefreshTokenVariables = {
        input: {
          refreshToken: this.refreshToken,
        },
        useCookies: this.useCookies,
      };

      const response = (await this.fetch({
        method: 'POST',
        url: '/query',
        headers: {
          'x-request-id': requestId,
        },
        body: {
          operationName: 'RefreshToken',
          query: print(gql`
            mutation RefreshToken(
              $input: RefreshTokenInput!
              $useCookies: Boolean!
            ) {
              refreshToken(input: $input) {
                auth {
                  id
                  scopes
                  ... on Auth @skip(if: $useCookies) {
                    authToken
                    refreshToken
                  }
                }
                intercomHashes {
                  android
                  id
                  ios
                  web
                }
                iterableAuthToken
                authInfo {
                  expiresAt
                  refreshExpiresAt
                }
              }
            }
          `),
          variables,
        },
      })) as GraphQLResponse<RefreshToken>;

      if (response.errors) {
        throw new AuthError(response.errors, requestId);
      }

      const refreshToken = response.data?.refreshToken;
      const auth = refreshToken?.auth;
      const authInfo = refreshToken?.authInfo;

      await this.updateState({
        ...this.state,
        status: Status.AUTHENTICATED,
        accessToken: auth?.authToken,
        refreshToken: auth?.refreshToken,
        scopes: auth?.scopes,
        id: auth?.id,
        otherStuff: {
          intercom_hashes: refreshToken?.intercomHashes,
          iterableAuthToken: refreshToken?.iterableAuthToken,
        },
        authInfo: authInfo
          ? {
              expiresAt: authInfo?.expiresAt,
              refreshExpiresAt: authInfo?.refreshExpiresAt,
            }
          : undefined,
      });
    } catch (error) {
      const castedError = error as Error;

      if (
        (error instanceof FetchError &&
          ![401, 403].includes(error.httpStatusCode)) ||
        /network/i.test(castedError.message)
      ) {
        this.ddRum?.(
          `Logout skipped: Fetch or network error from token refresh`,
        );
      } else {
        await this.logout();
      }

      throw castedError;
    }
  }

  private async performTokenRefresh(): Promise<void> {
    if (this.refreshing) {
      // wait for the already in-progress refresh to finish refreshing
      await this.refreshing;
      this.refreshing = undefined;
    } else if (
      getMillisecondsUntilIsExpired({
        jwtToken: this.accessToken,
        expiresAt: this.authInfo?.expiresAt,
      }) <= millisecondsBeforeAccessTokenExpiresToRefresh
    ) {
      // refreshing
      this.refreshing = this.refresh();
      try {
        await this.refreshing;
      } catch {
        // we'll rely on refreshToken to log out
      }

      this.refreshing = undefined;
    }
  }

  public async ensureToken(): Promise<string | undefined> {
    await this.performTokenRefresh();

    if (!this.accessToken && !this.useCookies) {
      throw new Error('No accessToken.');
    }

    return this.accessToken;
  }

  public async ensureScopes(): Promise<string[]> {
    await this.performTokenRefresh();

    if (!this.scopes) {
      throw new Error('No scopes.');
    }

    return this.scopes;
  }

  public async ensureIterableAuthToken(): Promise<string> {
    await this.performTokenRefresh();

    if (!this.otherStuff?.iterableAuthToken) {
      throw new Error('No iterableAuthToken.');
    }

    return this.otherStuff?.iterableAuthToken;
  }

  public async setPartialState(
    partialState: Partial<ClientState>,
  ): Promise<void> {
    await this.updateState({
      ...this.state,
      ...partialState,
    });
  }
}
